import * as React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PrismicLink from "../components/prismicLink"

const NotFoundPage = ({ data }) => {
    const document = data.prismicErrorPage.data

    return (
        <Layout>
            <SEO
                title={
                    document.seo_title.text ||
                    RichText.asText(document.page_title.raw)
                }
                description={document.seo_description?.text}
            />
            <div className="mx-auto px-6 pt-12 max-w-7xl">
                <div className="mb-4">
                    <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                        {RichText.asText(document.page_title.raw)}
                    </h1>
                </div>
                <div className="pb-20 prose prose-lg max-w-none text-gray-600">
                    <RichText
                        render={document.page_content.raw}
                        serializeHyperlink={PrismicLink}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage

export const query = graphql`
    query ErrorPageQuery {
        prismicErrorPage {
            data {
                page_title {
                    raw
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                page_content {
                    raw
                }
            }
        }
    }
`
